import React from "react"

import Layout from "../layout"
import Seo from "../seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { withTrans } from "../../i18n/withTrans"
import i18next from "../../i18n/config"

const Dozent = ({t, ...props}) => {
  const {
    pageContext: {
      nachname,
      vorname,
      lebensdaten,
      text,
      textEn,
      source,
      sourceEn,
      node,
      next,
      previous,
    },
  } = props

  const tr = (de,en)=>(i18next.language==="de"?de:en)

    return (
        <Layout>
            <Seo title={vorname+" "+nachname}/>
                <main id="maincontent">
                    <div style={{marginTop: "100px", marginBottom: "60px", minHeight:"100px"}}>
                        <GatsbyImage image={getImage(node)} style={{width:"100%", maxWidth:"500px", margin: "0 auto", display:"block"}}/>
                    </div>
                    <div style={{background: "#ffffff", padding:"20px"}}>
                        <div style={{paddingLeft:"max(calc(50vw - 250px), 20px)", paddingRight: "max(calc(50vw - 250px), 20px)", marginBottom:"0"}}>
                            <p className="p11G" style={{paddingTop:"50px"}}>{nachname}, {vorname}<br/>{lebensdaten}</p>
                            <br/>
                            <p className="p11G">{tr(text, textEn)}</p>

                            <p className="p11B"><br/>{t("person.bildquelle")}</p>
                            <p className="p11G" style={{paddingBottom:"50px"}}>{tr(source, sourceEn)}</p>

                            <p className="p11B" style={{marginTop:"40px", paddingBottom:"15px"}}>
                            { previous &&
                              <Link to={"/personen/"+previous.vorname.toLowerCase() + `-` + previous.nachname.toLowerCase()}
                                style={{textDecoration: "underline", color:"black", float:"left"}}>
                                  {t("person.prev")}
                              </Link>
                            }
                            { next &&
                              <Link to={"/personen/"+next.vorname.toLowerCase() + `-` + next.nachname.toLowerCase()}
                                style={{textDecoration: "underline", color:"black", float:"right"}}>
                                  {t("person.next")}
                              </Link>
                            }
                            </p>
                            <br/>
                        </div>
                    </div>
                </main>
        </Layout>
    )
}

export default withTrans(Dozent)
